import React from 'react';

import Seo from '../../components/seo/Seo';
import ServicesView from '../../views/ServicesView';

export default function Services() {
  return (
    <>
      <Seo currentRobots="noindex, nofollow" />
      <ServicesView />
    </>
  );
};